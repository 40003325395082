<template>
    <div class="wrap">
        <div class="manageBox">
            <div class="top">
                <el-form :inline="true" :model="queryParams" label-width="100px" class="demo-form-inline">
                    <el-form-item label="审核状态">
                        <el-select class="w200px" v-model="queryParams.examineResult" clearable placeholder="请选择审核状态">
                            <el-option v-for="item in stateOpt" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="当前审核单位">
                        <el-select class="w200px" v-model="queryParams.examineGrade" clearable placeholder="请选择当前审核单位">
                            <el-option v-for="(item, index) in examineGradeOpt" :key="index" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="搜索内容">
                        <el-input v-model="queryParams.keyWord" placeholder="机构名称/联系人/联系电话" clearable style="width: 200px" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="search" @click="searchList">搜索</el-button>
                        <el-button type="primary" class="search" plain @click="handlerResetList">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="content">
                <el-table v-loading="loading" :data="tableData" border style="width: 100%" :header-cell-style="headerCellStyle">
                    <template slot="empty">
                        <IsEmpty />
                    </template>
                    <el-table-column type="index" label="序号" align="center" width="80" />
                    <el-table-column prop="serviceName" align="center" label="机构名称" show-overflow-tooltip />
                    <el-table-column prop="creatTime" align="center" label="申请时间" width="160" />
                    <el-table-column prop="applicationType" align="center" label="申请类型">
                        <template v-slot="scope">
                            <span>{{ ['', '机构许可证申请', '营业执照申请'][scope.row.applicationType * 1] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="contactPerson" align="center" label="联系人" />
                    <el-table-column prop="contactPhone" align="center" label="联系电话" />
                    <el-table-column prop="contactAddress" align="center" label="机构地址" show-overflow-tooltip />
                    <el-table-column prop="totalBeds" align="center" label="总床位数（张）" />
                    <el-table-column prop="examineGrade" align="center" label="当前审核单位">
                        <template v-slot="scope">
                            <span>{{ ['', '社区', '街道', '民政'][scope.row.examineGrade * 1] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" align="center" label="审核状态">
                        <template v-slot="scope">
                            <span>{{ ['通过', '未通过', '', '待审核'][scope.row.examineResult * 1] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" fixed="right" min-width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click="seeDetial(scope.row)">查看</el-button>
                            <el-button type="text" v-if="!scope.row.examineState" @click="toReview(scope.row)">审核</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-if="total > 0"
                    style="margin: 20px 0"
                    background
                    layout="total, sizes, prev, pager, next"
                    :total="total"
                    :page-size="queryParams.pageSize"
                    :current-page="queryParams.pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { list } from '@/api/institutionalSubsidies'
import { parseTime } from '@/utils/index'
export default {
    data() {
        return {
            total: 0,
            loading: false,
            options: [],
            tableData: [],
            time: '',
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                examineResult: '',
                examineGrade: '',
                keyWord: ''
            },
            examineGradeOpt: [
                { label: '社区', value: 1 },
                { label: '街道', value: 2 },
                { label: '民政', value: 3 }
            ],
            stateOpt: [
                { label: '通过', value: 0 },
                { label: '未通过', value: 1 },
                { label: '待审核', value: 3 }
            ]
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        parseTime,
        seeDetial(row) {
            this.$router.push({
                path: '/applyExamine/serviceView',
                query: { rowId: row.id }
            })
        },
        toReview(row) {
            this.$router.push({
                path: '/applyExamine/serviceToReview',
                query: { rowId: row.id }
            })
        },
        // 管理人员列表
        async getList() {
            this.loading = false
            const res = await list(this.queryParams)
            this.tableData = res.data.rows
            this.total = res.data.total
        },
        searchList() {
            this.queryParams.pageNum = 1
            this.getList()
        },
        // 翻页
        handleCurrentChange(val) {
            this.loading = true
            this.queryParams.pageNum = val
            this.getList()
        },
        handleSizeChange(val) {
            this.loading = true
            this.queryParams.pageSize = val
        },
        handlerResetList() {
            this.queryParams = {
                pageNum: 1,
                pageSize: 10,
                state: '',
                examineGrade: '',
                keyWord: ''
            }
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>
.wrap {
    padding: 20px;
    background: #fff;
}
.manageBox {
    // margin: 20px;
    background: #fff;
    min-height: calc(100vh - 130px);
    .top {
        .search {
            margin-left: 20px;
        }
        .add {
            float: right;
        }
    }
    .eyes {
        width: 20px;
        height: 20px;
        float: right;
        margin-right: 20px;
        cursor: pointer;
    }
    .operation {
        margin-right: 20px;
        color: #3fcf89;
        cursor: pointer;
    }
    .stop {
        color: #f57473;
    }
    .widthStyle {
        width: 90%;
    }

    /*      /deep/ .el-form-item__content {
              margin-left: 55px !important;
              margin-right: 55px;
          }*/
}
.content {
    /*padding: 15px;*/
    padding-top: 20px;
}
.my_btn_duty {
    margin-right: 20px;
}
.el-button--text {
    outline: none;
    border: none;
}
.el-button--text:hover,
.el-button--text:focus {
    background: none !important;
    border: none !important;
    outline: none !important;
}
.el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus {
    color: #c0c4cc !important;
}
.w200px {
    width: 200px;
}
</style>
